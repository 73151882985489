<template>
  <v-app>
    <div class="register">
      <v-container>
        <v-row>
          <v-col>
            <div class="circle-logo text-center pt-12">
              <logo-img></logo-img>
            </div>
            <div class="logo-img">
              <div class="text-center">
                <logo class="logo" />
                <div class="caption primary--text">
                  Manager
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <div class="wrapper">
              <h1 class="h1 text-center">Create Account</h1>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  type="email"
                  label="Email"
                  v-model="email"
                  :rules="emailRules"
                  :error-messages="errors.email"
                  :loading="loading"
                  required
                ></v-text-field>
                <v-text-field
                  label="First Name"
                  v-model="first_name"
                  :rules="firstNameRules"
                  :error-messages="errors.first_name"
                  :loading="loading"
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Last Name"
                  v-model="last_name"
                  :rules="lastNameRules"
                  :error-messages="errors.last_name"
                  :loading="loading"
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Complete Address (optional)"
                  v-model="address"
                  :error-messages="addressErrors"
                  :loading="loading"
                >
                </v-text-field>
                <v-text-field
                  label="Contact Number (optional)"
                  type="number"
                  v-model="contact_number"
                  :error-messages="contactNumberErrors"
                  :loading="loading"
                ></v-text-field>
                <v-select
                  label="Choose Your Currency"
                  v-model="default_currency"
                  :items="currencies"
                  :rules="defaultCurrencyRules"
                  :error-messages="errors.default_currency"
                  :loading="loading"
                  required
                ></v-select>

                <v-text-field
                  type="password"
                  label="Password"
                  v-model="password"
                  :rules="passwordRules"
                  :error-messages="errors.password"
                  :loading="loading"
                  required
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  label="Confirm Password"
                  v-model="password_confirmation"
                  :error-messages="passwordConfirmationErrors"
                  :loading="loading"
                  required
                >
                </v-text-field>
                <recaptcha
                  :data-sitekey="recaptcha_key"
                  :data-callback="recaptchaCallback"
                  :data-validate="validate"
                  :data-size="'visible'"
                  :data-btn-disabled="!valid || loading"
                  :loading="loading"
                >
                </recaptcha>
       <!--          <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!valid || loading"
                  :loading="loading"
                  @click.prevent="submit"
                  block
                >
                  Register
                </v-btn> -->


                <p class="overline mt-6">
                  Already have an account?
                  <router-link :to="{name: 'Login'}">Sign In</router-link>
                  <br>
                  Forgot Password?
                  <router-link :to="{name: 'RequestPasswordReset'}">Reset password</router-link>
                  <br>
                  <router-link :to="{name: 'ResendConfirmationInstructions'}">
                    Didn't receive confirmation instructions?
                  </router-link>
                </p>

              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
  import Logo from '@/components/Logo'
  import LogoImg from '@/components/LogoImg'
  import { register } from '@/services/http/registrations'
  import { CURRENCIES } from '@/services/constants'
  import Recaptcha from '@/components/Recaptcha'

  export default {
    name: 'Register',
    components: {
      Logo, LogoImg, Recaptcha
    },
    data: () => ({
      recaptcha_key: process.env.VUE_APP_RECAPTCHA_KEY_2,
      valid: true,
      loading: false,
      email: '',
      first_name: '',
      last_name: '',
      address: '',
      contact_number: '',
      password: '',
      password_confirmation: '',
      isContactValid: false,
      isAddressValid: true,
      currencies: CURRENCIES,
      default_currency: 'PHP',
      // Rules
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      firstNameRules: [v => !!v || 'First name is required'],
      lastNameRules: [v => !!v || 'Last name is required'],
      passwordRules: [v => !!v || 'Password is required'],
      defaultCurrencyRules: [v => !!v || 'Please set your default currency'],

      // Server errors
      errors: {}
    }),
    methods: {
      validate () {
        return this.$refs.form.validate()
      },

      recaptchaCallback(token) {
        if (token) {
          this.submit(token);
         }else{
          alert('Please check captcha to verify you are not a robot');
        }
      },


      async submit(captcha_token) {
        try {
          this.loading = true
          const user = {
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            address: this.address,
            contact_number: this.contact_number,
            default_currency: this.default_currency,
            password: this.password,
            password_confirmation: this.password_confirmation,
            captcha_token: captcha_token
          }
          const reg = await register(user)
          this.$router.push({name: 'ConfirmAccount', params: reg})
        } catch(e) {
          console.log(e)
          this.errors = e
          window.grecaptcha.reset()
          /*this.$toast(e.toString())*/
        } finally {
          this.loading = false
        }
      }
    },
    computed: {
      passwordConfirmationErrors() {
        return this.password != this.password_confirmation
          ? 'Password did not match'
          : this.errors.password_confirmation
      },
      contactNumberErrors() {
      return this.isContactValid && this.contact_number.length !== 11
        ? 'Contact number must be 11 digits'
        : this.errors.contact_number;
      },
      addressErrors(){
        return !this.isAddressValid ? "Address can't be blank" : this.errors.address
      }

    },
    watch: {
      email(v) {
        if (v) this.errors = {}
      },
      password(v) {
        if (v) this.errors = {}
      },
      contact_number(v) {
        if(v) {
          this.errors = {}
          this.isContactValid = true;
        } else {
          this.isContactValid = false;
        }
      },
      address(v) {
        if(v) {
          this.errors = {} 
          if(v.trim().length === 0){
            this.isAddressValid  = false
          }
          else{
            this.isAddressValid = true;
          }
        }else{
          this.isAddressValid = true;
        }
    },
    }
  }
</script>
